import { flockTheme, LibraryThemeProvider, LoginForm } from '@flock/shared-ui'
import { ThemeProvider } from '@mui/system'
import React from 'react'
import { GATSBY_ENV } from '../constants'

const Login = () => (
  <ThemeProvider theme={flockTheme}>
    <LibraryThemeProvider>
      <LoginForm
        applicationName="Flock Homes Admin"
        disableMultifactor={GATSBY_ENV !== 'production'}
      />
    </LibraryThemeProvider>
  </ThemeProvider>
)

export default Login
